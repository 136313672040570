//add here your own js code. Vanilla JS welcome.
// var mapDiv = document.querySelector('#map'); // (removed : mapDiv Was replaced with Graphic)
var mlopsChart = document.querySelector('.mlops-stack-interactive-chart');

gsap.registerPlugin(
    MotionPathPlugin,
);


if (mlopsChart) {

    console.log('loaded stack 2');

    // querySelectorAll utility
    var getEls = function getEls(elmement) {
      var parent = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : document;
      return parent.querySelectorAll(elmement);
    };
  
    // querySelector utility
    var getEl = function getEl(elmement) {
      var parent = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : document;
      return parent.querySelector(elmement);
    };
  
    // get all selector elements
    var selectors = getEls(".option");
  
    // set gradient stop offsets
    var setStops = function setStops(stops) {
      stops.forEach(function (stop, i) {
        var s = gsap.utils.mapRange(0, stops.length - 1, 0, 1, i);
        stop.setAttribute("offset", s);
      });
    };
  
    // add or remove gradient stops and set gradient color
    var paint = function paint(target, color, add) {
      var paintTargets = getEls(".".concat(target));
      paintTargets.forEach(function (el) {
        if (add) {
          el.innerHTML += "<stop class=\"stop-".concat(target, "\" stop-color=\"").concat(color, "\"/>");
        } else {
          el.removeChild(getEl(".stop-".concat(target), el));
        }
  
        // get current gradients stops
        var stops = getEls("stop", el);
  
        // set current gradients stops
        setStops(stops);
      });
    };
  
    // click toggle tech-stack fills
    selectors.forEach(function (selector) {
      // set check colors for active state
      var color = selector.dataset.color;
      var check = selector.querySelector(".check");
      check.style.color = color;
      var target = "paint-".concat(selector.dataset.attr);
  
      // set initial active states
      if (selector.classList.contains("active")) {
        paint(target, color, true);
      }
  
      selector.addEventListener("click", function () {
        var add = true;
  
        if (selector.classList.contains("active")) {
          add = false;
          selector.classList.remove("active");
        } else {
          selector.classList.add("active");
        }
  
        paint(target, color, add);
      });
    });
  }





// $(function() {

// 	function shouldShowCookieBanner() {
// 		if (Cookies.get('pach_consent')==1) {
// 			return false;
// 		} 
// 		else {
// 			return true;
// 		}
// 	}

// 	if (shouldShowCookieBanner()) {
// 		$(".cookie-banner").show();
// 		$(".btn-close").click(function(){
// 			$(".cookie-banner").hide();
// 			Cookies.set('pach_consent', 1, { expires: 365 });
// 		});
// 	} 

// 	$(".btn-close").click(function(){
// 		$(".cookie-banner").hide();
// 	});

// });